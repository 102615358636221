<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-2 white pt-1 pb-1">
        <v-col cols="1" class="pl-3 pr-0 pt-3 pb-3 ma-auto">
            <v-skeleton-loader type="heading" />
        </v-col>
        <v-col v-if="hideAuthor" cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(45, 75) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(45, 75) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(35, 85) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(30, 45) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(30, 45) + '%'"
            />
        </v-col>
        <v-col v-if="!hideAuthor" cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(30, 45) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 py-0 ma-auto" />
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-2 align-center"
            :class="{
                white: !hover && !isActive,
                'concrete-solid': hover || isActive,
                'elevation-2': hasAction && (hover || isActive),
                'px-2': $vuetify.breakpoint.mdAndDown,
                'with-action': hasAction
            }"
            @click="onAction"
        >
            <v-col cols="1" class="position--relative">
                <status-chip
                    :status="isRejected ? 'Rejected' : request.status_string"
                    :tooltip="blockedReason"
                />
                <v-tooltip v-if="hasNewComments" bottom z-index="10">
                    <template #activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            class="new-comments--circle new-comments--circle-list"
                            v-on="on"
                        ></div>
                    </template>
                    <span>New comments in the last 48 hours</span>
                </v-tooltip>
            </v-col>
            <v-col
                v-if="hideAuthor"
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !hasHeadLine
                }"
            >
                <text-truncated :text="headline" />
            </v-col>
            <v-col
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !hasCategory
                }"
            >
                <text-truncated :text="category" />
            </v-col>
            <v-col
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !hasCompanyName
                }"
            >
                <text-truncated :text="companyName" />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 ma-auto">
                <author-request-type-chip
                    :request="request"
                    :assignment="request.latest_assignment"
                />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                {{ priorityLevel }}
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 ma-auto">
                {{ daysInQueue }}
            </v-col>
            <v-col v-if="!hideAuthor" cols="2" class="pl-2 pr-0">
                {{ authorName }}
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 py-0 ma-auto" align="right">
                <author-request-row-actions
                    :request="request"
                    @open="markRowActive"
                    @redirect="redirect"
                    @show-comments="showComments"
                />
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';

import {
    isTextSelectionEvent,
    formatDate,
    formatDateTime,
    randomBetween
} from '@/utils/helpers';
import { InjectReactive } from '@/utils/decorators';

import { PrEvents } from '@/mixins';

import { TextTruncated } from '@/components/TextTruncated';
import { StatusChip } from '@/components/StatusChip';
import { AuthorRequestMedia } from '@/components/Authoring/AuthorRequestMedia';
import AuthorRequestTypeChip from '@/components/Authoring/AuthorRequestsList/AuthorRequestTypeChip.vue';
import AuthorRequestRowActions from '@/components/Authoring/AuthorRequestsList/AuthorRequestRowActions.vue';

import type { AuthorRequest } from '@/types/AuthorRequest';
import type { RequestAction } from '@/components/Authoring/AuthorRequestActions/AuthorRequestActionsFactory';

const AuthorRequestPoolRowProps = Vue.extend({
    name: 'AuthorRequestPoolRow',
    props: {
        request: {
            type: Object as PropType<AuthorRequest>,
            default(): AuthorRequest | null {
                return null;
            }
        },
        action: {
            type: Object as PropType<RequestAction>,
            default(): RequestAction | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AuthorRequestRowActions,
        AuthorRequestTypeChip,
        AuthorRequestMedia,
        TextTruncated,
        StatusChip
    }
})
export default class AuthorRequestPoolRow extends mixins(
    AuthorRequestPoolRowProps,
    PrEvents
) {
    isActive = false;

    @InjectReactive({
        from: 'hideAuthor',
        default() {
            return false;
        }
    })
    hideAuthor!: boolean;

    get priorityLevel() {
        return this.request.latest_assignment?.priority_level;
    }

    get authorName() {
        if (!this.request.latest_assignment?.author) {
            return '-';
        }
        return [
            this.request.latest_assignment?.author.firstname,
            this.request.latest_assignment?.author.lastname
        ]
            .filter(Boolean)
            .join(' ');
    }

    get headline() {
        return (
            this.request?.latest_assignment?.announcement?.press_release
                ?.headline || 'No Headline Written'
        );
    }

    get hasHeadLine() {
        return this.request?.latest_assignment?.announcement?.press_release
            ?.headline;
    }

    get companyName() {
        return this.request?.company?.name || 'No Company Selected';
    }

    get hasCompanyName() {
        return this.request?.company?.name;
    }

    get category() {
        return this.request?.category?.name || 'No Category Selected';
    }

    get hasCategory() {
        return this.request?.category?.name;
    }

    get hasAction() {
        return Boolean(this.action);
    }

    get daysInQueue() {
        if (!this.request?.latest_assignment?.created) {
            return 1;
        }

        return (
            this.$dayjs().diff(
                this.$dayjs.unix(this.request?.latest_assignment?.created),
                'days'
            ) || 1
        );
    }

    get events() {
        return this.request?.latest_assignment?.announcement?.press_release
            ?.pr_events;
    }

    get hasNewComments() {
        if (!this.events) {
            return false;
        }

        return this.getNewCommentsForLast48Hours(this.events);
    }

    get isRejected() {
        return (
            this.request?.latest_assignment?.announcement?.press_release
                ?.status === 3
        );
    }

    get blockedReason() {
        if (this.request?.latest_assignment?.status !== 4 || !this.events) {
            return '';
        }

        const lastEvent = this.events
            .reverse()
            .find(event => event.message_type === 6);

        return lastEvent ? lastEvent.message : '';
    }

    formatDate = formatDate;
    formatDateTime = formatDateTime;
    randomBetween = randomBetween;

    markRowActive(isActive: boolean) {
        this.isActive = isActive;

        this.$emit('activate', this.request);
    }

    showComments() {
        this.$emit('show-comments', this.request.id);
    }

    onAction(e: Event) {
        if (this.hasAction && !isTextSelectionEvent(e)) {
            const result = this.action.callback(this, this.request);

            if (result) {
                if (this.action.link) {
                    this.redirect(result);
                }

                if (this.action.route) {
                    this.$router.push(result);
                }
            }
        }
    }

    redirect(location: string) {
        window.location.href = location;
    }
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';
</style>
